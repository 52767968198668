import React, { useEffect } from "react";

import postscribe from "postscribe";

import SimpleLanding from "../../../layouts/simple-landing";
import SEO from "../../../components/seo/seo";

const MortgageAppLogin = () => {
  const SEOData = {
    title: "Mortgage Login",
    meta: [
      {
        name: "title",
        content: "HELOC POC Login"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  useEffect(() => {
    postscribe("#heloc-poc-login", `<script src= "https://widget.ellieservices.com/latest/launcher.js" ></script>`);
  }, []);

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <div id="heloc-poc-login"></div>
      <div
        class="em-widget"
        data-width="100%"
        data-height="1200px"
        data-min-height="800px"
        data-site-id="1233063672"
        data-config='{"externalLoginWindow":"true"}'
        data-name="ecc-borrower-portal"
        data-aria-label="Borrower Portal"
      ></div>
    </SimpleLanding>
  );
};

export default MortgageAppLogin;
